/*
 * Colors used in the application.
 */

const Colors = {
  white: '#ffffff',
  light: '#f6f1ed',
  lightGrey: '#e5dada',
  dark: '#b5a397',
  darker: '#6a5750',
  darkest: '#30231d',
  closeIcon: '#969ea4'
}

export default Colors
